import attachmentsConfig from "components/attachments/attachments.config.json"
import notesConfig from "components/daily/notes/notes.config.json"
import worklogsConfig from "components/daily/worklogs/worklogs.config.json"
import filtersConfig from "components/filters/filters.config.json"
import planscanConfig from "components/planscan/planscan.config.json"
import { getFuzzySearch } from "components/protest.utils.web"
import get from "lodash/get"
import { DateTime } from "luxon"
/**
 *
 * @param input query
 * @param operator operator
 * @param sortedSearchKeys sortedSearchKeys
 * @returns keys
 */
export function checkForSearchKeys(
    input: string,
    operator: string,
    sortedSearchKeys: {
        key: string
        value: string
    }[]
): string[] {
    let keys = input.split(operator)
    const actualKey = sortedSearchKeys.find((item) => item.key.toLowerCase() === keys[0].toLowerCase())
    keys[0] = actualKey ? actualKey.value : keys[0]
    keys = [keys[0], keys[1]]
    return keys
}

/**
 *
 * @param recordValue recordValue to compare
 * @param selectedValue selected value to compare
 * @returns true if record value is greater than selected value
 */
function compareDateOrNumber<T extends Record<string, string>>(recordValue: T, selectedValue: number | string): boolean {
    if (!isNaN(Number(recordValue)) && typeof selectedValue === "number") {
        if (Number(recordValue) < selectedValue) return true
        return false
    } else if (DateTime.isDateTime(recordValue) && typeof selectedValue === "string") {
        const selectedDate = DateTime.fromISO(selectedValue)

        if (recordValue.isValid && selectedDate.isValid && recordValue > selectedDate) return true
        return false
    } else if (typeof recordValue === "string" && typeof selectedValue === "string") {
        const recordValueDateTime = DateTime.fromISO(recordValue)
        const selectedValueDateTime = DateTime.fromISO(selectedValue)

        if (recordValueDateTime.isValid && selectedValueDateTime.isValid && recordValueDateTime > selectedValueDateTime) return true
        return false
    } else {
        return false
    }
}

/**
 *
 * @param input query
 * @param operators operators
 * @returns operator
 */
export function getOperator(input: string, operators: string[]): string | undefined {
    const operator = operators.find((sign) => {
        return input.includes(sign)
    })

    return operator
}

/**
 * This takes available records and applies the search query as per the component name specified in the queryText
 *
 * @param allRecords all records
 * @param queryText query text
 * @param searchFilterKeys search filter keys
 * @param component component name
 * @returns filtered records
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function applySearchFilter<T extends Record<string, any>>(
    allRecords: T[],
    queryText: string,
    searchFilterKeys: searchFilterKey[],
    component: "Milestone" | "Activity" | "Constraint" | "Worklog" | "Note" | "Attachment"
): T[] {
    if (!queryText) return allRecords
    const queries = queryText.split("&&")
    const operators = filtersConfig.operators.map((operator) => {
        return operator.symbol
    })

    let queryComponent = ""
    const fuseQuery: { key: string; value: string; operator?: string }[] = []
    for (const query of queries) {
        if (query === "") continue

        if (query.includes("component==")) {
            queryComponent = query.replace("component==", "")
            continue
        }

        let key = ""
        let value = ""
        const operator = operators.find((sign) => {
            return query.includes(sign)
        })

        if (!operator) {
            fuseQuery.push({
                key: "#",
                value: query
            })
        } else {
            // TODO : Find better way to replace charecters
            key = query.split(operator)[0].trim()
            value = query.split(operator)[1].trim()

            // If component in query doesn't match with the component, we don't create fuseQuery. We just ignore it.
            if (queryComponent.includes(component)) {
                fuseQuery.push({
                    key: key,
                    value: value,
                    operator: operator
                })
            }
        }

        queryComponent = ""
    }

    let childAreas: string[] = []
    fuseQuery.forEach((query) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!query) return
        let isArray = false
        if (query.key === "data.areas" || query.key === "data.tags" || query.key === "data.predecessor") {
            isArray = true
            if (query.value !== "None" && query.value !== "Any") {
                allRecords = allRecords.map((record) => {
                    const array = get(record, query.key)
                    // @ts-ignore Need to concatenate array as string to make it work with Fuse.js
                    if (Array.isArray(array) && array.length > 0) record.concatenatedArray = array.join(", ")
                    // @ts-ignore Need to concatenate array as string to make it work with Fuse.js
                    else record.concatenatedArray = "None"
                    return record
                })

                if (query.key === "data.areas") {
                    const values = query.value.split(",")
                    for (const val of values) childAreas.push("" + val)
                }

                query.key = "concatenatedArray"
            }
        }

        const keys =
            query.key === "#"
                ? searchFilterKeys.map((key) => {
                      return key.value
                  })
                : [query.key]

        let value = query.value

        const entry = searchFilterKeys.find((option) => {
            return option.value === keys[0]
        })

        if ((entry && (entry.showDatePicker || entry.isComparable)) || query.value === "None" || query.value === "Any") {
            allRecords = allRecords.filter((record) => {
                const data = get(record, query.key)
                switch (query.operator) {
                    case "==":
                        if (query.value === "None") return isArray && data ? data.length === 0 : data === "" || !data
                        if (query.value === "Any" && isArray && data) return data.length > 0
                        if (query.value === "Any" && data !== "") return data
                        return data === (entry && entry.isComparable && !isNaN(Number(query.value)) ? Number(query.value) : query.value)

                    case "!=":
                        if (query.value === "None" && isArray && data) return data.length > 0
                        if (query.value === "None" && data !== "") return data
                        if (query.value === "Any") return isArray && data ? data.length === 0 : data === "" || !data
                        return data !== (entry && entry.isComparable && !isNaN(Number(query.value)) ? Number(query.value) : query.value)

                    case ">":
                        if (data && entry && entry.isComparable) return compareDateOrNumber(data, query.value)
                        else return false

                    case "<":
                        if (data && entry && entry.isComparable) return !compareDateOrNumber(data, query.value)
                        else return false

                    case "~=":
                        if (query.value === "None" && isArray && data) return data.length > 0
                        if (query.value === "None") return data === "" || !data
                        if (query.value === "Any" && isArray && data) return data.length > 0
                        if (query.value === "Any" && data !== "") return data
                    default:
                        return record
                }
            })
        } else if (entry && entry.isUndefined && query.value === "undefined") {
            const keys = query.key.split(".")
            allRecords = allRecords.filter((record) => record[keys[0]][keys[1]] === undefined)
        } else {
            if (query.operator === "!=") {
                if (query.key === "concatenatedArray") {
                    const areasToExclude = new Set(childAreas.map(Number))
                    allRecords = allRecords.filter((record) => {
                        if (record.data.areas) return !record.data.areas.some((area: number) => areasToExclude.has(area))
                        return true
                    })

                    childAreas = []
                }

                value = '!"' + value + '"'
            } else if (query.operator === "~=") {
                value = value
            }

            const filteredKeys: string[] = []
            searchFilterKeys.forEach((searchFilterKey) => {
                if (searchFilterKey.ignoreSearchFilter !== true && keys.includes(searchFilterKey.value)) filteredKeys.push(searchFilterKey.value)
            })

            const allRawRecords = allRecords

            filteredKeys.push("areaPath")
            const searchText = [value, ...childAreas.filter((child) => !child.includes(value))]
            if (childAreas.length > 0) allRecords = getFuzzySearch(allRecords, filteredKeys, searchText)
            else allRecords = getFuzzySearch(allRecords, filteredKeys, value)
            if (component === "Milestone") allRecords.push(...allRawRecords.filter((record) => record.areaPath === ""))
        }
    })

    return allRecords
}

/**
 * @returns appropriate icon for the record
 * @param recordType Component name
 */
export function getIconForRecordType(recordType: string | undefined): string {
    switch (recordType) {
        case "Activity":
            return planscanConfig.icons.activity

        case "Constraint":
            return planscanConfig.icons.constraint

        case "Milestone":
            return planscanConfig.icons.milestone

        case "Worklog":
            return worklogsConfig.worklog_icon

        case "Note":
            return notesConfig.note_icon

        case "Attachment":
            return attachmentsConfig.attachment_icon

        default:
            if (recordType && recordType.includes("Planscan")) return planscanConfig.icons.planscan
            if (recordType && recordType.includes("Daily")) return "fi-daily-filled"
            return "mdi-text-search"
    }
}
