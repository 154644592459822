import Logger from "common/Logger"; const LOGGER = new Logger("areas.utils.ts");import http, { isHttpError } from "common/http"
import areasConfig from "components/project/areas/areas.config.json"
import { getNameAbbreviation } from "components/protest.utils.web"
import { UsersSchemaWithId } from "components/users/users.schema"
import { TreeContent } from "components/widgets/treeView/treeView"
import { DateTime } from "luxon"
import Vue from "vue"
import { Component } from "vue-property-decorator"

import { ProjectSchemaWithId } from "../project.schema"
import areasDb from "./areas.db"
import { AreasSchema } from "./areas.schema"

/**
 * Create blank area
 *
 * @param requiredUserId user id
 * @param title
 * @param abbreviation
 * @param wbs_id
 * @returns ActivitiesSchema blank activity
 */
export function blankArea(title: string, abbreviation?: string, wbs_id?: string): AreasSchema {
    const currentTime = DateTime.utc().toISO()
    const data: AreasSchema = {
        created_by: -1,
        date_created: currentTime,
        date_modified: currentTime,
        modified_by: -1,
        data: {
            sub_areas: [],
            parent_area: -1,
            title,
            metadata: {
                wbs_id
            },
            abbreviation
        }
    }

    return data
}

/**
 * @param subAreaIds Array of ids
 * @param itemToPush TreeContent object
 * @param projectId project id
 */
export async function populateTree(subAreaIds: Array<string>, itemToPush: TreeContent, projectId: number): Promise<void> {
    const childAreas = await areasDb.readAll({
        filter: {
            id: subAreaIds
        }
    })

    if (isHttpError(childAreas)) {
        LOGGER.error("Failed to fetch areas from AreaDb", { error: childAreas })
        return
    }

    itemToPush.children = []
    for (const areaId of subAreaIds) {
        const childArea = childAreas.find((area) => area.id === +areaId)
        if (childArea) {
            const childItemToPush = {
                id: childArea.id.toString(),
                name: childArea.data.title,
                children: childArea.data.sub_areas.length > 0 ? [] : undefined,
                childrenId: childArea.data.sub_areas,
                parentId: itemToPush.id === "-1" ? undefined : itemToPush.id,
                wbsId: childArea.data.metadata.wbs_id
            }

            itemToPush.children.push(childItemToPush)
        }
    }
}

/**
 * get cached Path of area
 *
 * @param areaID Area id
 * @param areaPathCache area Path Cache
 * @returns area name
 */
export function getPathNameNoDb(areaID: number, areaPathCache?: Record<string, string>): string {
    if (areaID === -1) return ""
    if (areaPathCache) {
        const cachedPath = areaPathCache[areaID]
        if (cachedPath) return cachedPath
    }

    return ""
}

/**
 * @param areaID Area id to find string for
 * @param areaPathCache A key value object containing cached area path names
 * @param abbreviate boolean to check if user want to abbrivate or not
 * @param sessionID session id
 * @returns area hierarchy string
 */
export async function getPathName(areaID: number, areaPathCache?: Record<string, string>, abbreviate?: boolean, sessionID?: string): Promise<string> {
    if (areaID === -1) return ""
    if (areaPathCache) {
        const cachedPath = areaPathCache[areaID]
        if (cachedPath) return cachedPath
    }

    let areaPath = ""

    const parentAreas = await areasDb.readParents(areaID, "parent_area", sessionID)

    if (isHttpError(parentAreas)) {
        LOGGER.error("Failed to get area parents information.", { error: parentAreas })
        return ""
    }

    for (const area of parentAreas) {
        if (area.depth > 0) {
            if (abbreviate) areaPath = area.data.abbreviation ? area.data.abbreviation : getNameAbbreviation(area.data.title, 3) + " > " + areaPath
            else areaPath = area.data.title + " > " + areaPath
        } else {
            areaPath = area.data.title
        }
    }

    if (areaPathCache) areaPathCache[areaID] = areaPath

    return areaPath
}

/**
 * Returns id for given  area path
 *
 * @param areaPath Full path name of area
 * @param rootIds Root Ids of areas at root level
 * @returns number Area Id
 */
export async function getAreaCodeFromString(areaPath: string, rootIds: Array<number>): Promise<number> {
    let areaId = -1
    const delimiter = ">"

    const areaNames = areaPath.split(delimiter)
    const rootAreaName = areaNames[0].trim()

    const rootAreas = await areasDb.readAll({
        filter: {
            id: rootIds
        }
    })

    if (isHttpError(rootAreas)) return -1

    const rootArea = rootAreas.find((area) => area.data.title.trim() === rootAreaName)
    if (rootArea) {
        if (areaNames.length === 1) {
            return rootArea.id
        } else {
            areaNames.splice(0, 1)
            if (rootArea.data.sub_areas.length > 0) areaId = await getAreaCodeFromString(areaNames.join(delimiter), rootArea.data.sub_areas)
            else return -1
        }
    }

    return areaId
}

/**
 * @param wbsId The wbs id.
 * @returns Promise<Array<number>>
 */
export async function getAreaFromWBS(wbsId: string): Promise<(AreasSchema & RequiredId)[]> {
    const area = await areasDb.readAll({
        filter: {
            "data.metadata.wbs_id": wbsId
        }
    })

    if (isHttpError(area)) {
        LOGGER.error("Failed to fetch areas from AreaDb", { error: area })
        return []
    }

    if (isHttpError(area)) return []
    else return area
}

/**
 * Check if an area is in use in any activity in current project
 *
 * @param areaId Area Id to check
 * @param projectId ProjectId to check in
 * @returns boolean is area in use
 */
export async function isAreaInUse(areaId: number, projectId: number): Promise<boolean> {
    const isInUse = await http.get<boolean>(`${process.env.FLYPAPER_API_URL}/areas/is/in/use/${areaId}/${projectId}`)

    if (isHttpError(isInUse)) return false

    return isInUse
}

/**
 * @param areaId The area id number.
 * @returns Promise<Array<number>>
 */
export async function getAncestors(areaId: number): Promise<Array<number>> {
    const parentIds: Array<number> = []
    const area = await areasDb.read(areaId)

    if (isHttpError(area) || area.length === 0) return []

    if (area[0].data.parent_area !== -1) parentIds.push(...(await getAncestors(area[0].data.parent_area)), area[0].data.parent_area)

    return parentIds
}

/**
 * Get child area ids of a area
 *
 * @param areaId area Id for which children are to be get
 * @param sessionId the session id number
 * @returns Array<number> child area ids
 */
export async function getAllChildAreas(areaId: number, sessionId?: string | undefined): Promise<Array<number>> {
    const childAreaIds = []
    const area = await areasDb.read(areaId, sessionId)

    if (isHttpError(area)) return []

    for (const subAreaId of area[0].data.sub_areas) {
        childAreaIds.push(subAreaId)
        childAreaIds.push(...(await getAllChildAreas(subAreaId, sessionId)))
    }

    return childAreaIds
}

/**
 *
 * @param topLevelProjectAreas
 * @param sessionId
 */
export async function getProjectAreaSequence(topLevelProjectAreas: number[], sessionId?: string) {
    const allAreaIds = []
    for (const parentArea of topLevelProjectAreas) {
        allAreaIds.push(parentArea)
        const allAreas = await getAllChildAreas(parentArea, sessionId)
        for (const area of allAreas) allAreaIds.push(area)
    }

    return allAreaIds
}

@Component
export class AreasUtilsMixin extends Vue {
    public getMailSubjectLine(user: UsersSchemaWithId, project: ProjectSchemaWithId): string {
        return user.data.name + areasConfig.request_area.email_subject + project.data.name
    }
}
