import Logger from "common/Logger"; const LOGGER = new Logger("protest.utils.web.ts");import { abbreviate } from "common/abbreviate"
import { defaultFuzzySearchFuseOptions } from "common/FuseOptions"
import { WorklogsSchema } from "components/daily/worklogs/worklogs.schema"
import { ActivitiesSchema } from "components/planscan/activities/activities.schema"
import { ConstraintsSchema } from "components/planscan/constraints/constraints.schema"
import { MilestonesSchema } from "components/planscan/milestones/milestones.schema"
import { PlanscanSchema } from "components/planscan/planscan.schema"
import Fuse from "fuse.js"
import get from "lodash/get"
import { DateTime } from "luxon"

import { getPathNameNoDb } from "./project/areas/areas.utils"

/**
 *
 * @param allRecords items to gruoup by tags
 * @param sortDesc sort boolean
 * @returns grouped items
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupItemsByTags<T extends { data: { tags: string[] } } & Record<string, any>>(allRecords: T[], sortDesc: boolean): Dictionary<T[]> {
    const tags: string[] = []
    const locallyGroupedItems: Dictionary<T[]> = {}

    for (const record of allRecords) {
        for (const tag of record.data.tags) {
            if (!tags.includes(tag)) {
                tags.push(tag)
                locallyGroupedItems[tag] = []
            }
        }

        if (record.data.tags.length > 0) {
            record.data.tags.sort().map((tag: string | number) => {
                locallyGroupedItems[tag].push(record)
            })
        } else {
            if (!tags.includes("untagged")) {
                tags.push("untagged")
                locallyGroupedItems["untagged"] = []
            }

            locallyGroupedItems["untagged"].push(record)
        }
    }

    // Sort tags alphabetically
    tags.sort()
    if (sortDesc) tags.reverse()
    const groupedItems: Dictionary<T[]> = {}
    for (let i = 0; i < tags.length; i++) groupedItems[tags[i]] = locallyGroupedItems[tags[i]]

    return groupedItems
}

/**
 *
 * @param allRecords items to gruoup by tags
 * @param areaSequence area index sequancs
 * @param sortDesc sort boolean
 * @returns grouped items
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupItemsByAreas<T extends Record<string, any>>(allRecords: T[], areaSequence: number[], sortDesc: boolean): Dictionary<T[]> {
    const areaIndices: number[] = []
    const locallyGroupedItems: Dictionary<T[]> = {}
    const areasWithId: {
        id: number
        path: string
        index: number
    }[] = []

    for (const record of allRecords) {
        if (record.areaPath && record.areaPath !== "") {
            const sepratedAreaPaths: string[] = record.areaPath.split(",")

            sepratedAreaPaths.map((areaPath, index) => {
                const areaName = areaPath.trim()
                const isAreaPresent = areasWithId.find((area) => {
                    return area.id === record.data.areas[index]
                })

                if (!isAreaPresent) {
                    areasWithId.push({
                        id: record.data.areas[index],
                        path: areaName,
                        index: areaSequence.indexOf(record.data.areas[index])
                    })
                }
            })
        }
    }

    for (const record of allRecords) {
        if (record.data.areas && record.data.areas.length > 0) {
            for (const areaId of record.data.areas) {
                const presentArea = areasWithId.find((area) => {
                    return area.id === areaId
                })

                if (!presentArea) continue

                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (!locallyGroupedItems[presentArea.index]) locallyGroupedItems[presentArea.index] = []
                locallyGroupedItems[presentArea.index].push(record)
                if (!areaIndices.includes(presentArea.index)) areaIndices.push(presentArea.index)
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!locallyGroupedItems[areaSequence.length + 1]) {
                areaIndices.push(areaSequence.length + 1)
                locallyGroupedItems[areaSequence.length + 1] = []
            }

            locallyGroupedItems[areaSequence.length + 1].push(record)
        }
    }

    areaIndices.sort()
    if (sortDesc) areaIndices.reverse()
    const groupedItems: Dictionary<T[]> = {}
    for (let i = 0; i < areaIndices.length; i++) groupedItems[areaIndices[i]] = locallyGroupedItems[areaIndices[i]]

    return groupedItems
}

// @ts-ignore nothing
const stableSort = (arr, compare) =>
    arr
        // @ts-ignore nothing
        .map((item, index) => ({ item, index }))
        // @ts-ignore nothing
        .sort((a, b) => compare(a.item, b.item) || a.index - b.index)
        // @ts-ignore nothing
        .map(({ item }) => item)

/**
 * @param records records data
 * @param sortBy sort string
 * @param sortDesc is descending sort
 * @returns sorted records data
 */
export function sortRecords<T>(records: T[], sortBy: string, sortDesc: boolean): T[] {
    return stableSort(records, (a: T, b: T) => {
        const upDown = sortDesc ? -1 : 1
        const aValueRaw = get(a, sortBy)
        const bValueRaw = get(b, sortBy)
        const aDate = DateTime.fromISO(aValueRaw)
        const bDate = DateTime.fromISO(bValueRaw)
        const aValue = aDate.isValid && bDate.isValid ? aDate : aValueRaw
        const bValue = aDate.isValid && bDate.isValid ? bDate : bValueRaw
        // @ts-ignore Using Dynamic keys of JSON as an index type.
        return aValue <= bValue ? upDown : upDown * -1
    })
}

/**
 * Create blank schedule
 *
 * @returns PlanscanSchema blank schedule
 */
export async function blankSchedule(): Promise<PlanscanSchema> {
    const data: PlanscanSchema = {
        created_by: 0,
        date_created: "",
        date_modified: "",
        modified_by: 0,
        data: {
            activities: [],
            constraints: [],
            milestones: [],
            title: "",
            type: ""
        }
    }

    return data
}

/**
 * @param record worklog record
 * @returns returns true if record is worklog
 */
export function isWorklog(record: ConstraintsSchema | ActivitiesSchema | MilestonesSchema | WorklogsSchema): WorklogsSchema | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if ((record as WorklogsSchema).data.required_user_id !== undefined) return record as WorklogsSchema
    return
}

/**
 * @returns group name to displayd
 * @param key current group name key
 * @param groupBy group by
 * @param projectAreaSequence projectAreaSequence
 * @param currentProjectAreaPathNames currentProjectAreaPathNames
 */
export function getGroupName(
    key: string,
    groupBy: string,
    projectAreaSequence: number[],
    currentProjectAreaPathNames: Record<string, string>
): string {
    if (groupBy === "data.areas" || groupBy === "areas") {
        const areaId = projectAreaSequence[Number(key)]
        return areaId > 0 ? getPathNameNoDb(areaId, currentProjectAreaPathNames) : "Unspecified"
    }

    return key
}

/**
 * Get name and generate abbreviation
 *
 * @param {string} name name to generate abbreviation for
 * @param length length
 * @returns string
 */
export function getNameAbbreviation(name: string, length: number): string {
    let companyNameAbbreviation = ""
    companyNameAbbreviation = abbreviate(name, { length: length })
    return companyNameAbbreviation
}

/**
 *
 * @param itemsList itemslist
 * @param keywordsList keylist
 * @param searchText searchtext
 * @returns item or items list
 */
export function getFuzzySearch<T>(itemsList: T[], keywordsList: string[], searchText: string | string[]): T[] {
    try {
        const updatedKeywordsList = keywordsList.filter((keyword) => keyword !== "lastScan")
        const fuse = new Fuse(itemsList, defaultFuzzySearchFuseOptions(updatedKeywordsList))
        if (typeof searchText === "string") {
            return fuse.search(searchText).map((result) => {
                return result.item
            })
        } else {
            const records: T[] = []
            searchText.forEach((text) => {
                const res = fuse.search(text.toString()).map((result) => {
                    return result.item
                })

                res.forEach((item) => {
                    records.push(item)
                })
            })

            return records
        }
    } catch (err) {
        LOGGER.error("Filters fuzzySearch error:.", { error: err })
        return itemsList
    }
}

/**
 *
 * @param hideExtensionHeader hideHeader
 * @returns style for header
 */
export function hidePanelHeader(hideExtensionHeader?: boolean): string {
    if (hideExtensionHeader) return "display: none;"
    return "padding-left: 0px;  padding-bottom: 0px; padding-top: 0px;"
}
