import { DateTime } from "luxon"

/**
 * Get if preset has dailies present in them
 *
 * @param  {string} preset preset string for date range
 * @param  {string[]} dailyDates daily dates for available dailies
 * @param  {string[]} monthlyDates monthly dates for available dailies
 * @returns boolean
 */
export function isDateValid(preset: string, dailyDates: string[], monthlyDates: string[]): boolean {
    switch (preset) {
        case "Today": {
            const todayDate = DateTime.local().toFormat("yyyy-MM-dd")
            return dailyDates.includes(todayDate)
        }

        case "Yesterday": {
            const yesterday = DateTime.local().minus({ days: 1 }).toFormat("yyyy-MM-dd")

            return dailyDates.includes(yesterday)
        }

        case "This Week": {
            let startWeek = DateTime.local().startOf("week")

            for (let day = 0; day < 7; day++) {
                const date = startWeek.toFormat("yyyy-MM-dd")
                if (dailyDates.includes(date)) return true
                startWeek = startWeek.plus({ days: 1 })
            }

            return false
        }

        case "Last Week": {
            const lastWeek = DateTime.local().startOf("week").minus({ days: 1 })
            let startLastWeek = lastWeek.startOf("week")
            for (let day = 0; day < 7; day++) {
                const date = startLastWeek.toFormat("yyyy-MM-dd")
                if (dailyDates.includes(date)) return true
                startLastWeek = startLastWeek.plus({ days: 1 })
            }

            return false
        }

        case "This Month": {
            const startOfMonth = DateTime.local().startOf("month").toISODate()
            const splitDate = startOfMonth.split("-")
            const monthDate = splitDate[0] + "-" + splitDate[1]

            if (monthlyDates.includes(monthDate)) return true
            return false
        }

        case "Last Month": {
            const lastMonth = DateTime.local().startOf("month").minus({ days: 1 })
            const startOfLastMonth = lastMonth.startOf("month").toISODate()
            const splitDate = startOfLastMonth.split("-")
            const monthDate = splitDate[0] + "-" + splitDate[1]

            if (monthlyDates.includes(monthDate)) return true

            return false
        }

        case "This Year": {
            const startOfYear = DateTime.local().startOf("year").toISODate()
            const year = startOfYear.split("-")[0]

            return monthlyDates.some((monthDate) => monthDate.split("-")[0] === year)
        }

        case "Last Year": {
            const lastYear = DateTime.local().startOf("year").minus({ days: 1 })
            const startOfLastYear = lastYear.startOf("year").toISODate()
            const year = startOfLastYear.split("-")[0]

            return monthlyDates.some((monthDate) => monthDate.split("-")[0] === year)
        }

        case "All Time": {
            return dailyDates.length > 0
        }
    }

    return false
}

/**
 * Get date range from a given preset name
 *
 * @param  {string} preset preset string for date range
 * @returns string[]
 */
export function datePresets(preset: string): string[] {
    switch (preset) {
        case "Today": {
            const now = DateTime.local().toISODate()
            return [now, now]
        }

        case "Yesterday": {
            const yesterday = DateTime.local().minus({ days: 1 }).toISODate()
            return [yesterday, yesterday]
        }

        case "This Week": {
            const start = DateTime.local().startOf("week").toISODate()
            const end = DateTime.local().endOf("week").toISODate()
            return [start, end]
        }

        case "This Month": {
            const start = DateTime.local().startOf("month").toISODate()
            const end = DateTime.local().endOf("month").toISODate()
            return [start, end]
        }

        case "This Year": {
            const start = DateTime.local().startOf("year").toISODate()
            const end = DateTime.local().endOf("year").toISODate()
            return [start, end]
        }

        case "Last Week": {
            const lastWeek = DateTime.local().startOf("week").minus({ days: 1 })
            const start = lastWeek.startOf("week").toISODate()
            const end = lastWeek.endOf("week").toISODate()
            return [start, end]
        }

        case "Last Month": {
            const lastMonth = DateTime.local().startOf("month").minus({ days: 1 })
            const start = lastMonth.startOf("month").toISODate()
            const end = lastMonth.endOf("month").toISODate()
            return [start, end]
        }

        case "Last Year": {
            const lastYear = DateTime.local().startOf("year").minus({ days: 1 })
            const start = lastYear.startOf("year").toISODate()
            const end = lastYear.endOf("year").toISODate()
            return [start, end]
        }
    }

    return [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)]
}
