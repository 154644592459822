import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"fToolbar"}},[_c(VToolbar,{class:_vm.roundedTop ? 'rounded-t' : '' || _vm.roundedBottom ? 'rounded-b' : '' || _vm.backgroundColor,attrs:{"absolute":_vm.absolute,"rounded":_vm.getRounded,"dense":_vm.dense ? _vm.dense : _vm.stickyMode ? _vm.stickyMode : _vm.$store.state.userPreferences.compactMode,"flat":_vm.flat ? _vm.flat : _vm.stickyMode ? !_vm.stickyMode : _vm.$store.state.userPreferences.compactMode}},[(_vm.icon)?[_c(VIcon,[_vm._v(_vm._s(_vm.icon))]),_vm._v(" ")]:_vm._e(),(_vm.title)?[_c(VToolbarTitle,{class:_vm.titleClass,style:(_vm.customStyle)},[_vm._v(_vm._s(_vm.title))])]:_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"text-subtitle-1 font-weight-thin mt-1"},[_vm._v("  "+_vm._s(_vm.subtitle))]):_vm._e(),_c(VSpacer),_vm._t("default"),_c('ButtonDisplay',{attrs:{"buttonBreakpoint":_vm.buttonBreakpoint,"buttonMenuMinWidth":_vm.buttonMenuMinWidth},scopedSlots:_vm._u([{key:"menu-buttons-sm",fn:function(){return [_vm._t("menu-buttons-sm")]},proxy:true},{key:"menu-buttons-md",fn:function(){return [_vm._t("menu-buttons-md")]},proxy:true},{key:"menu-buttons-lg",fn:function(){return [_vm._t("menu-buttons-lg")]},proxy:true},{key:"menu-buttons",fn:function(){return [_vm._t("menu-buttons")]},proxy:true}],null,true)},[_vm._t("menu-buttons-breakpoint")],2),(_vm.tutorialTags || _vm.tutorialCategory)?_c(VMenu,{attrs:{"close-on-content-click":false,"offset-x":"","offset-y":"","transition":"scale-transition","maxWidth":"600","minWidth":"600","maxHeight":"700","origin":"top right"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g({on:{"click":function () { _vm.compactView = true; }}},on),[_vm._v("mdi-bookshelf")])]}}],null,false,2459833541),model:{value:(_vm.showTotorialCompactView),callback:function ($$v) {_vm.showTotorialCompactView=$$v},expression:"showTotorialCompactView"}},[_c(VSheet,{attrs:{"hide-overlay":"","no-click-animation":"","scrollable":""}},[_c('Tutorials',{attrs:{"category":_vm.tutorialCategory,"tags":_vm.tutorialTags,"compactView":_vm.compactView},on:{"onShowDetailedView":_vm.onShowDetailedView}})],1)],1):_vm._e()],2),_vm._t("below"),_c('FDialog',{attrs:{"title":_vm.tutorialTitle(_vm.title),"width":"750px","max-width":"100%","hideCancelAndDone":"","fullscreen":""},on:{"cancel":function($event){_vm.showTutorialDialog = false}},model:{value:(_vm.showTutorialDialog),callback:function ($$v) {_vm.showTutorialDialog=$$v},expression:"showTutorialDialog"}},[(_vm.tutorialTags || _vm.tutorialCategory)?_c('Tutorials',{attrs:{"category":_vm.tutorialCategory,"tags":_vm.tutorialTags}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }